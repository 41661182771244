import { SocialFilter } from '@widgets/social/socialFilter/SocialFilter';
import { SocialPagination } from '@widgets/social/socialPagination/SocialPagination';
import { SocialPosts } from '@widgets/social/socialPosts/SocialPosts';
import * as React from 'react';

const SocialPage = () => {
  return null;

  // return (
  //   <>
  //     <div className="container_xl pt-6 pb-12 lg:pt-12 xl:pb-52 relative overflow-hidden">
  //       <h1 className="text-2xl lg:text-4xl">Очима наших єДопоможців</h1>
  //       <SocialFilter />
  //       <SocialPosts />
  //       <SocialPagination />
  //     </div>
  //   </>
  // );
};

export default SocialPage;
